import React, { useState, useEffect } from "react";
import osm from "./osm-providers";
import L from "leaflet";
import { MapContainer, TileLayer, FeatureGroup, GeoJSON, LayersControl } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import sigpac_data from "./Recinto1.json";
import axios from "axios";
import endpoint_data from "./output.json";
import "leaflet-geometryutil";
import * as turf from '@turf/turf';
import "./styles.css";
const apiUrl = process.env.REACT_APP_API_URL;
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const DrawMap = () => {
  const [center, setCenter] = useState({ lat: '37.43564738753886', lng: '-5.04738675789662' });
  const ZOOM_LEVEL = 15;
  const mapRef = useRef();

  const [hectareaPoligono, setHectareaPoligono] = useState([]);
  const [polygon, setPolygon] = useState([]);
  const [polygonsFarmingDB, setPolygonsFarmingDB] = useState(null);
  const [polygonsIrrigationDB, setPolygonsIrrigationDB] = useState(null);
  const [polygonsTratamFarmingDB, setPolygonsTratamFarmingDB] = useState(null);
  const [polygonsTratamIrrigationDB, setPolygonsTratamIrrigationDB] = useState(null);
  const [polygonSelected, setPolygonSelected] = useState(null);

  const fetchPolygons = async (type) => {
    try {
      const response = await axios.get(`${apiUrl}/polygons?type=${type}`);
      if (type === 'CULTIVO') {
        setPolygonsFarmingDB(response.data);

      } else if (type === 'REGADIO') {
        setPolygonsIrrigationDB(response.data);

      } else if (type === 'TRAT_REGADIO') {
        setPolygonsTratamIrrigationDB(response.data);

      } else if (type === 'TRAT_CULTIVO') {
        setPolygonsTratamFarmingDB(response.data);
      }
    } catch (error) {
      console.error('Error fetching polygons:', error);
      console.error('apiurl:', apiUrl);
    }
  };

  useEffect(() => {

    if (!polygonsFarmingDB) {
      fetchPolygons('CULTIVO');
    }

    if (!polygonsIrrigationDB) {
      fetchPolygons('REGADIO');
    }

    if (!polygonsTratamFarmingDB) {
      fetchPolygons('TRAT_CULTIVO');
    }

    if (!polygonsTratamIrrigationDB) {
      fetchPolygons('TRAT_REGADIO');
    }

  }, []);

  const _created = (e) => {
    if (e.layerType === 'polygon') {
      const polygon = L.polygon(e.layer.getLatLngs()[0]);
      const area = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0]);
      const areaInHectares = (area / 10000).toFixed(2);
      setHectareaPoligono(areaInHectares);
      e.layer.bindTooltip(`Área: ${areaInHectares} hectáreas`, { permanent: true }).openTooltip();
    }
    const { layer } = e;
    const latlngs = layer.getLatLngs()[0].map(latlng => ({ lat: latlng.lat, lng: latlng.lng }));
    setPolygon(latlngs);
  };

  function processDifferencePolygons(polygons, finalPolygon) {
    polygons.features.forEach(feature => {
      const coordinatesListPolygon = feature.geometry.coordinates[0];
      coordinatesListPolygon.push(coordinatesListPolygon[0]);
      const listPolygon = turf.polygon([coordinatesListPolygon]);

      const coordinatesFinalPolygon = finalPolygon.map(point => [point[1], point[0]]);
      coordinatesFinalPolygon.push(coordinatesFinalPolygon[0]);
      const newPolygon = turf.polygon([coordinatesFinalPolygon]);

      const intersection = turf.intersect(newPolygon, listPolygon);
      if (intersection != null) {
        finalPolygon = turf.difference(newPolygon, intersection);
        finalPolygon = finalPolygon.geometry.coordinates[0].map(point => [point[1], point[0]]);
      }
    });
    return finalPolygon;
  }
  function processIntersectionPolygons(polygons, finalPolygon) {
    polygons.features.forEach(feature => {
      const coordinatesListPolygon = feature.geometry.coordinates[0];
      coordinatesListPolygon.push(coordinatesListPolygon[0]);
      const listPolygon = turf.polygon([coordinatesListPolygon]);

      const coordinatesFinalPolygon = finalPolygon.map(point => [point[1], point[0]]);
      coordinatesFinalPolygon.push(coordinatesFinalPolygon[0]);
      const newPolygon = turf.polygon([coordinatesFinalPolygon]);

      const intersection = turf.intersect(newPolygon, listPolygon);
      if (intersection != null) {
        finalPolygon = intersection.geometry.coordinates[0].map(point => [point[1], point[0]]);
      }
    });
    return finalPolygon;
  }
  function processBiggestIntersectionPolygons(polygons, finalPolygon) {
    let biggestPolygon = finalPolygon;
    let biggestArea = 0;
    polygons.features.forEach(feature => {

      const coordinatesListPolygon = feature.geometry.coordinates[0]
      coordinatesListPolygon.push(coordinatesListPolygon[0])
      const listPolygon = turf.polygon([coordinatesListPolygon]);

      const coordinatesFinalPolygon = finalPolygon.map(point => [point[1], point[0]]);
      coordinatesFinalPolygon.push(coordinatesFinalPolygon[0]);
      const newPolygon = turf.polygon([coordinatesFinalPolygon]);

      const intersection = turf.intersect(newPolygon, listPolygon);

      if (intersection != null) {
        const area = turf.area(intersection);
        if (area > biggestArea) {
          biggestArea = area;
          biggestPolygon = intersection.geometry.coordinates[0].map(point => [point[1], point[0]]);
        }
      }
    });
    return biggestPolygon;
  }
  const guardarCoordenadas = async (type) => {
    try {
      if (!polygon) {
        alert('Selecciona un polígono');
        return;
      }
      let formattedPolygon = polygon.map(pol => Object.values(pol));
      let finalPolygon = formattedPolygon;

      if (type === "REGADIO" || type === "CULTIVO") {

        finalPolygon = processIntersectionPolygons(sigpac_data, finalPolygon);
        finalPolygon = processDifferencePolygons(polygonsFarmingDB, finalPolygon);
        finalPolygon = processDifferencePolygons(polygonsIrrigationDB, finalPolygon);

      } else if (type === 'TRAT_REGADIO') {

        finalPolygon = processBiggestIntersectionPolygons(polygonsIrrigationDB, finalPolygon);
        finalPolygon = processDifferencePolygons(polygonsTratamIrrigationDB, finalPolygon);

      } else if (type === 'TRAT_CULTIVO') {

        finalPolygon = processBiggestIntersectionPolygons(polygonsFarmingDB, finalPolygon);
        finalPolygon = processDifferencePolygons(polygonsTratamFarmingDB, finalPolygon);

      }

      await axios.post(apiUrl + '/polygons/new', { coordinates: finalPolygon, hectares: hectareaPoligono, type: type });
      alert('Guardado correctamente');
      window.location.reload();
    }
    catch (error) {
      console.error('Error al guardar las coordenadas:', error);
      alert('Ocurrió un error al guardar las coordenadas de regadio');
    }
  }

  const SaveControls = () => {
    return (
      <div className="save-controls">
        <div className="leaflet-control leaflet-bar save-button">
          <button className="save-button__button save-button__button--regadio" onClick={() => guardarCoordenadas("REGADIO")}>Guardar regadío</button>
        </div>
        <div className="leaflet-control leaflet-bar save-button">
          <button className="save-button__button save-button__button--tratregadio" onClick={() => guardarCoordenadas("TRAT_REGADIO")}>Guardar tratamiento regadío</button>
        </div>
        <div className="leaflet-control leaflet-bar save-button">
          <button className="save-button__button save-button__button--cultivo" onClick={() => guardarCoordenadas("CULTIVO")}>Guardar cultivo</button>
        </div>
        <div className="leaflet-control leaflet-bar save-button">
          <button className="save-button__button save-button__button--tratcultivo" onClick={() => guardarCoordenadas("TRAT_CULTIVO")}>Guardar tratamiento cultivo</button>
        </div>
        <div className="leaflet-control leaflet-bar save-button">
          <button className="save-button__button save-button__button--delete" onClick={handleDelete}>Eliminar polígono</button>
        </div>
      </div>
    );
  };

  const handleDelete = async () => {
    try {
      if (!polygonSelected) {
        alert('Selecciona un polígono para eliminar');
        return;
      }
      await axios.delete(`${apiUrl}/polygons/${polygonSelected.properties.id}/delete`);
      alert('Polígono eliminado correctamente');
      window.location.reload();
    } catch (error) {
      console.error('Error al eliminar el polígono:', error);
      alert('Ocurrió un error al eliminar el polígono');
    }
  }

  const handleClick = (feature, layer) => {
    if (polygonSelected && polygonSelected.properties.id === feature.properties.id) {
      setPolygonSelected(null);
    } else {
      setPolygonSelected(feature);
    }
  };

  const styleFunction = (feature) => {
    if (feature.properties.type === 'cultivo' || feature.properties.type === 'regadio') {
      if (polygonSelected && polygonSelected.properties.id === feature.properties.id) {
        return { fillOpacity: 0.6 };
      }

    } else if (feature.properties.type === 'trat_cultivo' || feature.properties.type === 'trat_regadio') {
      if (polygonSelected && polygonSelected.properties.id === feature.properties.id) {
        return { fillOpacity: 0.9 };
      } else {
        return { fillOpacity: 0.6 };
      }
    }
    return { fillOpacity: 0.1 };

  };

  return (
    <>
      <div className="row">
        {polygonsFarmingDB && polygonsIrrigationDB && polygonsTratamIrrigationDB && polygonsTratamFarmingDB && (

          <div className="col text-center">
            <div className="col">
              <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>

                <TileLayer
                  url={osm.maptiler.url}
                  attribution={osm.maptiler.attribution}
                />

                <LayersControl position="topright">
                  <FeatureGroup>
                    <EditControl
                      position="topright"
                      onCreated={_created}
                      draw={{
                        circle: false,
                        circlemarker: false,
                        marker: false,
                        rectangle: false,
                        polyline: false
                      }}
                    />
                  </FeatureGroup>

                  <LayersControl.Overlay name="SIGPAC Recinto" checked>
                    <GeoJSON
                      data={sigpac_data}
                      pathOptions={{ color: 'red', opacity: 1, fill: false }}
                    />
                  </LayersControl.Overlay>

                  <LayersControl.Overlay name="SIGPAC Endpoint">
                    <GeoJSON
                      data={endpoint_data}
                      pathOptions={{ color: 'yellow', opacity: 1, fill: true }} />
                  </LayersControl.Overlay>

                  <LayersControl.Overlay name="Cultivo" checked>
                    <GeoJSON
                      data={polygonsFarmingDB}
                      pathOptions={{ color: 'green', opacity: 1, fill: true }}
                      onEachFeature={(feature, layer) => {

                        layer.on('click', () => {
                          setPolygonSelected(feature);
                        });

                        layer.on('dblclick', () => {
                          setPolygonSelected(null);
                        });

                        if (feature.properties.hectares) {
                          layer.bindTooltip(`Area (h): ${feature.properties.hectares}`).openTooltip();
                        }
                      }}
                      style={styleFunction}
                    />
                  </LayersControl.Overlay>

                  <LayersControl.Overlay name="Regadío" checked>
                    <GeoJSON
                      data={polygonsIrrigationDB}
                      pathOptions={{ color: 'blue', opacity: 1, fill: true }}
                      onEachFeature={(feature, layer) => {

                        layer.on('click', () => {
                          handleClick(feature, layer);
                        });

                        layer.on('dblclick', () => {
                          setPolygonSelected(null);
                        });

                        if (feature.properties.hectares) {
                          layer.bindTooltip(`Area (h): ${feature.properties.hectares}`).openTooltip();
                        }

                      }}
                      style={styleFunction}
                    />
                  </LayersControl.Overlay>
                  <svg width="0" height="0">
                    <defs>
                      <pattern id="stripedIrrigationPattern" patternUnits="userSpaceOnUse" width="10" height="10">
                        <line x1="0" y1="0" x2="10" y2="10" style={{ stroke: 'blue', strokeWidth: 2 }} />
                      </pattern>
                      <pattern id="stripedFarmingPattern" patternUnits="userSpaceOnUse" width="10" height="10">
                        <line x1="0" y1="0" x2="10" y2="10" style={{ stroke: 'green', strokeWidth: 3 }} />
                      </pattern>
                    </defs>
                  </svg>
                  <LayersControl.Overlay name="Tratamiento Regadío" checked>
                    <GeoJSON
                      data={polygonsTratamIrrigationDB}
                      pathOptions={{ color: 'blue', opacity: 0.1, fill: true, fillColor: 'url(#stripedIrrigationPattern)' }}
                      onEachFeature={(feature, layer) => {

                        layer.on('click', () => {
                          handleClick(feature, layer);
                        });

                        layer.on('dblclick', () => {
                          setPolygonSelected(null);
                        });

                        if (feature.properties.hectares) {
                          layer.bindTooltip(`Area (h): ${feature.properties.hectares}`).openTooltip();
                        }

                      }}
                      style={styleFunction}
                    />
                  </LayersControl.Overlay>
                  <LayersControl.Overlay name="Tratamiento Cultivo" checked>
                    <GeoJSON
                      data={polygonsTratamFarmingDB}
                      pathOptions={{
                        color: 'green',
                        opacity: 0.1,
                        fill: true,
                        fillColor: 'url(#stripedFarmingPattern)'
                      }}
                      onEachFeature={(feature, layer) => {

                        layer.on('click', () => {
                          handleClick(feature, layer);
                        });

                        layer.on('dblclick', () => {
                          setPolygonSelected(null);
                        });

                        if (feature.properties.hectares) {
                          layer.bindTooltip(`Area (h): ${feature.properties.hectares}`).openTooltip();
                        }

                      }}
                      style={styleFunction}
                    />
                  </LayersControl.Overlay>

                </LayersControl>

                <SaveControls />
              </MapContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DrawMap;
