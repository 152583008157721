import './App.css';
import DrawMap from './components/draw';

function App() {
  return (
    <DrawMap/>
  );
}

export default App;
